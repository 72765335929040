<template>
  <div class="overflow-auto overflow-md-hidden vh-100 bg-theme position-relative">
    <div v-if="$store.state.isFailedReauthenticating"
    class="alert alert-danger d-flex gap-4 align-items-center position-absolute w-75 mx-auto" style="left: 15%; top:15px; z-index: 1;">
      <i class="bi bi-exclamation-circle-fill"></i>
      <span>Your Previous Session was Expired. Please Login Again if you must.</span>
      <button @click="$store.commit('close_expired')" type="button" class="bg-transparent border-0 ms-auto"><i class="bi bi-x-square-fill" style="height: 25px; width: 25px;"></i></button>
    </div>
    <div id="hero" class="login-bg h-100">
      <div class="container p-4 p-md-5 h-100">
        <div id="main-container" class="pb-3 pb-md-0 d-flex flex-column flex-md-row justify-content-between gap-2 gap-lg-4 mt-0 mt-md-3 mt-lg-0">
          <div class="text-center text-lg-start mt-lg-5 w-50 px-lg-4 px-xl-5 mx-auto" data-aos="fade-right" data-aos-duration="1000">
            <div id="slogan">
              <img src="../assets/imgs/logo.png" alt="Tatsing-Group" class="my-2 my-md-5 w-100">
              <p class="text-white text-center" style="font-size: small;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae delectus eius accusantium nesciunt praesentium natus qui repellendus at eum, optio id rem minus quos enim assumenda sequi quidem!</p>
            </div>
          </div>
          <!-- Card -->
          <form @submit.prevent="login">
            <div class="text-center m-auto me-md-auto h-100" data-aos="fade-up" data-aos-duration="1500">
              <div id="login-card" class="card text-center px-2 px-lg-4 px-xl-5 py-4 py-md-5 h-100 d-flex flex-column justify-content-evenly ms-auto">
                <div class="card-title">
                  <h1 class="fs-3 fw-bold">Sign In</h1>
                  <span class="text-muted">To interact with your account</span>
                </div>
                <div class="card-body">
                  <span class="text-danger">{{ $store.state.errors.error_msg }}</span>
                  <div>
                    <input v-model="user.username" id="username" type="text" class="w-100 border-0 border-bottom my-3" :class="[[$store.state.errors.errors.username ? 'error' : '']]" name="username" :placeholder="[[ $store.state.errors.errors.username ? $store.state.errors.errors.username : 'Username' ]]" required >
                    <input v-model="user.password" id="password" type="password" class="w-100 border-0 border-bottom my-3" :class="[[$store.state.errors.errors.username ? 'error' : '']]" name="password" :placeholder="[[ $store.state.errors.errors.password ? $store.state.errors.errors.password : 'Password' ]]" required>

                    <div class="my-lg-3 my-4 d-flex flex-column gap-4">
                      <!-- <div class="d-flex flex-row align-items-center justify-content-between">
                          <div class="d-flex flex-row gap-2 align-items-center">
                              <input type="checkbox" name="Remember_me" id="remember_me" style="height: 30px;">
                              <label for="remember_me" class="text-muted">Remember me</label>
                          </div>
                          <a href="#" class="text-decoration-none text-secondary-theme" style="font-size: small;">Forgot Password?</a>
                      </div> -->
                      
                      <button class="btn btn-primary bg-dark btn-register w-100" type="submit" name="login_user">Login</button>
                    </div>
                  </div>
                </div>
                
                <span id="contact-us" class="text-center text-muted w-100 my-3" style="font-size: small;">Having any problems? <a href="#" class="link-dark fw-bold text-decoration-underline">Contact Us.</a></span>
                <span id="terms-policy" class="text-center mb-lg-4" style="font-size: x-small;">Read our <a href="#" class="link-dark text-decoration-underline">Terms and Privacy Policy.</a></span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    user: {
      username: "",
      password: ""
    }
  }),
  methods: {
    async login() {
      await this.$store.dispatch('loginUser', this.user);
      this.$router.push(this.$route.query.redirect ? this.$route.query.redirect : '/dashboard');
    }
  }
}
</script>

<style scoped src='../assets/css/login.css'></style>
